import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import transformProductCardMiniData from '@activebrands/core-web/utils/product/transform-product-card-mini-data';
import BasketUpsell from 'components/Header/BasketOverlay/BasketUpsell';
import Summary from 'components/Header/BasketOverlay/Summary';
import ProductCardMini from 'components/product/ProductCardMini';

const ItemWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
    height: '100%',
    position: 'relative',
    padding: '12px 8px',
});

const Basket = ({ content = {}, upsellProducts = [] }) => {
    const [css] = useStyletron();

    const items = useSelector(state => state.basket.items);
    const totals = useSelector(state => state.basket.totals);

    const upsellLabel = content.upsellLabel;
    const showProducts = items?.length > 0;

    const productsTotals = [
        {
            label: fm('Subtotal'),
            total: totals?.productsSum,
        },
    ];

    return (
        <ItemWrapper>
            <ScrollableBox
                withScrollbar
                $style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}
            >
                {showProducts && (
                    <div className={css({ paddingRight: '8px' })}>
                        {items?.map(item => {
                            const transformedItem = transformProductCardMiniData({ product: item, source: 'Centra' });
                            return (
                                <ProductCardMini
                                    $style={{ marginBottom: '8px' }}
                                    key={item.id}
                                    trackingList="Basket Products"
                                    type="basket"
                                    {...transformedItem}
                                    media={transformedItem.media?.[0] ?? {}}
                                    onClick={() => {
                                        overlay.close('basket');
                                    }}
                                />
                            );
                        })}
                    </div>
                )}
                <BasketUpsell heading={upsellLabel} products={upsellProducts} />
            </ScrollableBox>
            <Summary overlay={overlay} totals={productsTotals} />
        </ItemWrapper>
    );
};

Basket.propTypes = {
    content: PropTypes.object,
    upsellProducts: PropTypes.array,
};

export default Basket;
