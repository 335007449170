import fm from 'format-message';
import PropTypes from 'prop-types';
import useCheckoutUrlQuery from 'queries/useCheckoutUrlQuery';
import Link from '@activebrands/core-web/components/Link';
import { styled } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';
import Paragraph from 'components/text/Paragraph';

const SummaryWrapper = styled('div', {
    width: '100%',
    paddingTop: '8px',
    borderTop: '1px solid var(--color-contrast-low)',
    backgroundColor: 'var(--color-bg-header)',
});

const Subtotal = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 24px',
});

const Summary = ({ totals, overlay }) => {
    const { checkoutPage } = useCheckoutUrlQuery();

    const closeOverlay = () => {
        overlay.close('basket');
    };

    return (
        <SummaryWrapper>
            {totals.map(item => (
                <Subtotal key={item.label}>
                    <Paragraph>{item.label}:</Paragraph>
                    <Paragraph>{item.total}</Paragraph>
                </Subtotal>
            ))}
            <div onClick={closeOverlay}>
                <ThemeButton $style={{ width: '100%', marginTop: '8px' }} as={Link} theme="inverted" to={checkoutPage}>
                    {fm('Go to checkout')}
                </ThemeButton>
            </div>
        </SummaryWrapper>
    );
};

Summary.propTypes = {
    overlay: PropTypes.object.isRequired,
    totals: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, total: PropTypes.string })).isRequired,
};

export default Summary;
